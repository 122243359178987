import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';
import {
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import { EmailTooltipComponent } from '../../components';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[kayMasonFoundationEmailTooltip]',
  standalone: true,
  exportAs: 'emailTooltipDirective'
})
export class EmailTooltipDirective {
  @Input() public email: string | undefined;
  @Input() public name: string | undefined;
  @Input() public status: string | undefined;
  @Input() public statusImg: string | undefined;

  private readonly _overlayRef: OverlayRef | undefined;

  public constructor(
    private readonly _elementRef: ElementRef,
    private readonly _overlay: Overlay,
    private readonly _overlayPositionBuilder: OverlayPositionBuilder
  ) {
    const overlayConfig: OverlayConfig = new OverlayConfig({
      positionStrategy: this._overlayPositionBuilder
        .flexibleConnectedTo(this._elementRef)
        .setOrigin(this._elementRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom'
          }
        ]),
      scrollStrategy: this._overlay.scrollStrategies.close(),
      minWidth: 400,
      minHeight: 20
    });

    this._overlayRef = this._overlay.create(overlayConfig);
  }

  @HostListener('mouseover', ['$event']) public onMouseOver(
    event: MouseEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();

    const emailTooltipPortal: ComponentPortal<EmailTooltipComponent> =
      new ComponentPortal(EmailTooltipComponent);
    const emailTooltipComponentRef:
      | ComponentRef<EmailTooltipComponent>
      | undefined = this._overlayRef?.attach(emailTooltipPortal);

    if (emailTooltipComponentRef) {
      emailTooltipComponentRef.instance.data = {
        email: this.email ? this.email : '',
        name: this.name ? this.name : '',
        status: this.status ? this.status : '',
        statusImg: this.statusImg ? this.statusImg : ''
      };
    }
  }

  @HostListener('mouseout', ['$event'])
  public onMouseOut(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this._overlayRef?.detach();
  }

  public close(): void {
    this._overlayRef?.detach();
  }
}
