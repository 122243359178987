<div class="bg-white px-6 py-4">
  <h4 class="text-md font-bold">{{ data.name }}</h4>
  <p class="text-sm">{{ data.email }}</p>
  <mat-chip class="mt-4">
    <ng-container *ngIf="data.statusImg.length > 0">
      <img matChipAvatar [src]="data.statusImg" alt="" />
    </ng-container>
    {{ data.status }}
  </mat-chip>
</div>
