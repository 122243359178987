import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public apiUrl: string = environment.apiUrl;

  public constructor(private readonly _http: HttpClient) {}

  // Drop downs
  public getRelationshipOptions(): Array<string> {
    return [
      'Father',
      'Mother',
      'Legal Guardian'
    ];
  }

  public getLanguages(): Array<string> {
    const languages: Array<string> = [
      'isiZulu',
      'isiXhosa',
      'Afrikaans',
      'English',
      'Setswana',
      'Sesotho',
      'Xitsonga',
      'siSwati',
      'Tshivenda',
      'isiNdebele',
      'South African Sign Language (SASL)',
      'other'
    ];

    return languages;
  }

  public getProvinces(): Array<string> {
    const provinces: Array<string> = [
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'KwaZulu-Natal',
      'Limpopo',
      'Mpumalanga',
      'Northern Cape',
      'North West',
      'Western Cape'
    ];

    return provinces;
  }

  public getCities(): Array<string> {
    const cities: Array<string> = [
      'Johannesburg',
      'Cape Town',
      'Durban',
      'Pretoria',
      'Port Elizabeth',
      'Bloemfontein',
      'Pietermaritzburg',
      'Polokwane',
      'Nelspruit',
      'Kimberley'
    ];

    return cities;
  }

  public getIncomeRanges() : Array<string>{
    return [
      'R0 - R2000',
      'R2000 - R4000',
      'R4000 - R6000',
      'R6000 - R8000',
      'R8000 - R10000',
      'R10000 - R12000',
      'R12000 - R14000',
      'R14000 - R16000',
      'R16000 - R17500',
      'More than R17500'
    ];
  }

  public getCountries(): Array<string> {
    const countries: Array<string> = [
      'Afghanistan',
      'Albania',
      'Algeria',
      'Andorra',
      'Angola',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegovina',
      'Botswana',
      'Brazil',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Cape Verde',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Colombia',
      'Comoros',
      'Congo',
      'Costa Rica',
      "Côte d'Ivoire",
      'Croatia',
      'Cuba',
      'Cyprus',
      'Czech Republic',
      "Democratic People's Republic of Korea",
      'Democratic Republic of the Congo',
      'Denmark',
      'Djibouti',
      'Dominica',
      ' Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Fiji',
      'Finland',
      'France',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Greece',
      'Grenada',
      'Guatemala',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Honduras',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran (Islamic Republic of)',
      'Iraq',
      'Ireland',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Kuwait',
      'Kyrgyzstan',
      "Lao People's Democratic Republic",
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Mauritania',
      'Mauritius',
      'Mexico',
      'Micronesia (Federated States of)',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Poland',
      'Portugal',
      'Qatar',
      'Republic of Korea',
      'Republic of Moldova',
      'Romania',
      'Russian Federation',
      'Rwanda',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syrian Arab Republic',
      'Tajikistan',
      'Thailand',
      'The former Yugoslav Republic of Macedonia',
      'Timor-Leste',
      'Togo',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom of Great Britain and Northern Ireland',
      'United Republic of Tanzania',
      'United States of America',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela (Bolivarian Republic of)',
      'Viet Nam',
      'Yemen',
      'Zambia',
      'Zimbabwe'
    ];

    return countries;
  }

  public getEducationLevels(): Array<string> {
    return [
      'No formal education',
      'Primary school',
      'High school',
      'Diploma',
      "Bachelor's degree",
      "Master's degree",
      'Doctorate'
    ];
  }

  public getTypesOfIncome(): Array<string> {
    return [
      'Salary',
      'Commission',
      'Bonuses',
      'Self-Employment Income',
      'Dividends',
      'Rental Income',
      'Royalties',
      'Pension',
      'Social Security Benefits',
      'Child Support',
      'Alimony',
      'Veterans Benefits',
      'Other'
    ];
  }

}
