import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadService } from '../../services';
import { Observable, Subject, startWith } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IUploadState } from '../../models';
import { DragAndDropDirective } from '../../directives';

@Component({
  selector: 'kay-mason-foundation-file-upload',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, DragAndDropDirective],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.css'
})
export class FileUploadComponent {
  public uploadState: IUploadState = {
    currentFile: null,
    fileName: 'Select File',
    progress: 0
  };

  @Input() public url : string = ''; // Required input for url for upload
  @Input() public applicationId : string = ''; // Required input for applicationId for upload
  @Input() public required : boolean = false; // Optional input for required file selection
  @Input() public allowedMimeTypes : string = ''; // Array to hold allowed MIME types
  @Input() public label : string = ''; // formControlName
  @Output() public readonly uploadSuccess : EventEmitter<null> = new EventEmitter(); // Event to emit upload URL
  @Output() public readonly uploadError : EventEmitter<null> = new EventEmitter<null>();

  public readonly progressSubject : Subject<number> = new Subject<number>(); // Private Subject for this instance
  public uploadProgress$ : Observable<number> = this.progressSubject.asObservable().pipe(startWith(0)); // Observable from the Subject

  private readonly unsubscribe$ : Subject<void> = new Subject<void>(); // For unsubscribing

  public constructor(private readonly _fileService: FileUploadService) {}

  public dragAndDropFile(files: Array<File> | null) : void {
    if (files && files[0]) {
      const file: File = files[0];
      this.uploadState.currentFile = file;
      this.uploadState.fileName = this.uploadState.currentFile.name;
      this.upload();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectFile(event: any): void {
    event.stopPropagation();

    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.uploadState.currentFile = file;
      this.uploadState.fileName = this.uploadState.currentFile.name;
      this.upload();
    } else {
      this.uploadState.fileName = 'Select File';
    }
  }

  // openFile(element : HTMLElement){
  //   element.click();
  // }

  public upload(): void {
    this.uploadState.progress = 0;
    const formData : FormData = new FormData();
    if (this.uploadState.currentFile) {
      formData.append('file', this.uploadState.currentFile);
      this._fileService
        .uploadFile(formData, this.url, this.applicationId)
        .subscribe({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          next: (res: any) => {
            // eslint-disable-next-line @typescript-eslint/typedef
            const isProgressEvent =
              res.type === HttpEventType.UploadProgress || res.type === 1; // Handle both numeric 1 and HttpEventType.UploadProgress
            if (isProgressEvent) {
              this.progressSubject.next(
                Math.round((100 * res.loaded) / res.total)
              );
            } else if (res instanceof HttpResponse) {
              if (res.status === 200 || res.status === 201) {
                this.uploadSuccess.emit();
              }
            }
          },
          error: () => {
            this.uploadError.emit();
          }
        });
    }
  }
}
