<div class="border-b border-grey bg-[#E6FBFF] p-2 w-full shadow">
  <div class="flex justify-end items-center gap-5">
    <img src="/assets/Map.svg" class="cursor-pointer" alt="logo" (click)="goto()"/>

    <!--
          <mat-form-field appearance="outline">
            <mat-select (valueChange)="changeLanguage($event)" [(ngModel)]="selectedOption">
                <mat-option value="en">English</mat-option>
                <mat-option value="xh">IsiXhosa</mat-option>
                <mat-option value="af">Afrikaans</mat-option>
            </mat-select>
          </mat-form-field> -->

    <div class="text-xs">
      <form [formGroup]="languageSelector" class="bg-white flex items-center p-2 rounded-md">
        <svg
          style="width: 16px; height: 16px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M177.8 63.2l10 17.4c2.8 4.8 4.2 10.3 4.2 15.9v41.4c0 3.9 1.6 7.7 4.3 10.4c6.2 6.2 16.5 5.7 22-1.2l13.6-17c4.7-5.9 12.9-7.7 19.6-4.3l15.2 7.6c3.4 1.7 7.2 2.6 11 2.6c6.5 0 12.8-2.6 17.4-7.2l3.9-3.9c2.9-2.9 7.3-3.6 11-1.8l29.2 14.6c7.8 3.9 12.6 11.8 12.6 20.5c0 10.5-7.1 19.6-17.3 22.2l-35.4 8.8c-7.4 1.8-15.1 1.5-22.4-.9l-32-10.7c-3.3-1.1-6.7-1.7-10.2-1.7c-7 0-13.8 2.3-19.4 6.5L176 212c-10.1 7.6-16 19.4-16 32v28c0 26.5 21.5 48 48 48h32c8.8 0 16 7.2 16 16v48c0 17.7 14.3 32 32 32c10.1 0 19.6-4.7 25.6-12.8l25.6-34.1c8.3-11.1 12.8-24.6 12.8-38.4V318.6c0-3.9 2.6-7.3 6.4-8.2l5.3-1.3c11.9-3 20.3-13.7 20.3-26c0-7.1-2.8-13.9-7.8-18.9l-33.5-33.5c-3.7-3.7-3.7-9.7 0-13.4c5.7-5.7 14.1-7.7 21.8-5.1l14.1 4.7c12.3 4.1 25.7-1.5 31.5-13c3.5-7 11.2-10.8 18.9-9.2l27.4 5.5C432 112.4 351.5 48 256 48c-27.7 0-54 5.4-78.2 15.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
          />
        </svg>
        <select formControlName="lang" (change)="changeLanguage()" class="py-2 px-4 rounded" name="language-picker-select">
          <option value="en" selected>English</option>
          <option value="xh">IsiXhosa</option>
          <option value="af">Afrikaans</option>
        </select>
      </form>
    </div>
  </div>
  <div>
    <img
      routerLink="/"
      src="/assets/logo.gif"
      class="h-10 cursor-pointer"
      alt="logo"
    />
  </div>
</div>
