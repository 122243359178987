<div class="">
  <label class="text-xs font-semibold" 
    >{{ label }}
    <span *ngIf="required" class="text-red-600 font-bold text-xs"
      >*</span
    ></label
  >
  <div class="flex items-center justify-center w-full">
    <label
    (click)="fileInput.click" kayMasonFoundationDragAndDrop (fileDropped)="dragAndDropFile($event)"
      class="flex flex-col items-center justify-center w-full h-52 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
    >
      <div class="flex flex-col items-center justify-center pt-5 pb-6">
        <svg
          class="w-8 h-8 mb-4 text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 16"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
          />
        </svg>
        <p class="mb-2 text-sm text-gray-500">
          <span class="font-semibold">Click to upload</span> or drag and drop
        </p>
        <p class="text-xs text-gray-500">
          {{ uploadState.fileName }}
        </p>
      </div>
      <input
        (change)="selectFile($event)"
        [accept]="allowedMimeTypes"
        #fileInput
        type="file"
        class="hidden"
      />
    </label>
  </div>
  <div class="flex mt-4 items-center" *ngIf="uploadState.currentFile">
    <mat-progress-bar
      [value]="uploadProgress$ | async"
      class="flex-1"
    ></mat-progress-bar>
    <p class="font-semibold">{{ uploadProgress$ | async }}%</p>
  </div>
</div>
