import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private readonly apiUrl: string = environment.apiUrl;

  public constructor(private readonly _http: HttpClient) {}


  public uploadFile(
    file: FormData,
    url: string,
    applicationId: string
  ) : Observable<null>{
    return this._http.post<null>(
      `${this.apiUrl}/applications/${applicationId}/${url}`,
      file,
      {
        reportProgress: true
      }
    );
  }
  
}
