// language.service.ts

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private readonly Identifier : string = 'kay-mason-default-lang';
  public constructor(private readonly _translate: TranslateService) { }

  public setLanguage(language: string) : void {
    this._translate.use(language);
    localStorage.setItem(this.Identifier, language);
  }

  public getLanguage(): string {
    return localStorage.getItem(this.Identifier) || 'en'; // Default language if not set
  }
}
