import { inject } from '@angular/core';
import { TokenService } from '../services';
import { Router } from '@angular/router';

export const canActivate = () :boolean => {
  const authService : TokenService = inject(TokenService);
  const router : Router = inject(Router);

  if (authService.isAuthenticated()) {
    return true;
  } else {
    void router.navigate(['auth', 'login']);
    return false;
  }
};
